import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/structure/layout"
import { Container } from "../components/styled/container"
import { Hero } from "../components/styled/hero"
import { Colors, Sizes } from "../components/theme"

import { useGetAllPosts } from "../hooks/useGetAllPosts"

const LastestPosts = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 35px 0;
`

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: ${Sizes.mobile}) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.article`
  font-family: "Inter";
  background: white;
  border-radius: 5px;
  border: 2px solid ${Colors.scdsDark};
  padding: 10px 20px;
  text-decoration: none;
  font-size: 13px;
  flex-basis: 0;
  flex-grow: 1;
  min-height: 260px;
  box-shadow: -5px 5px 0px -1px #036a6c2e;
  a {
    text-decoration: none;
  }
  h2 {
    color: ${Colors.scdsDark};
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    text-decoration: none;
  }

  h3 {
    font-size: 10px;
    color: ${Colors.scdsLight};
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    font-family: "Merriweather";
    color: ${Colors.scdsDark};
    font-size: 13px;
    line-height: 17px;
  }
`

const Categories = styled.div`
  display: flex;
`
const Publicaciones = () => {
  const posts = useGetAllPosts("es")

  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  return (
    <Layout>
      <Hero>
        <Container>
          <h1>{intl.formatMessage({ id: "publications_title" })}</h1>
          <p>
          </p>
        </Container>
      </Hero>
      <Container>
        <LastestPosts>
          {intl.formatMessage({ id: "home_last_month_posts" })}
        </LastestPosts>
        <PostsContainer>
          {locale === "es"
            ? posts.spanish.edges.map(post => (
                <Card key={post.node.id}>
                  <Link to={`/${post.node.fields.slug}`}>
                    <h2>{post.node.frontmatter.title}</h2>
                    <Categories>
                      {post.node.frontmatter.category.map(item => (
                        <h3 key={item}>{item}</h3>
                      ))}
                    </Categories>
                    <p>{post.node.frontmatter.description}</p>
                  </Link>
                </Card>
              ))
            : posts.english.edges.map(post => (
                <Card key={post.node.id}>
                  <Link to={`/${locale}/${post.node.fields.slug}`}>
                    <h2>{post.node.frontmatter.title}</h2>
                    <Categories>
                      {post.node.frontmatter.category.map(item => (
                        <h3 key={item}>{item}</h3>
                      ))}
                    </Categories>
                    <p>{post.node.frontmatter.description}</p>
                  </Link>
                </Card>
              ))}
        </PostsContainer>
      </Container>
    </Layout>
  )
}

export default Publicaciones
